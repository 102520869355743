/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import Raven from 'raven-js';
import isEmpty from 'lodash/isEmpty';

import connect from 'app/connect';
import { analyticService } from 'global/services';

/* CONTEXTS */
import {
    promocodeVar, selectedPeriodVar, subscriptionTypeVar,
} from 'app/apollo/reaction';
import { resetSelectedFiltersReaction } from 'app/hooks/useFilterHandlers';
import { OverlayContextDispatch, OverlayContextData } from 'app/containers/contexts/overlay.context';
import { abTestDataContext } from 'app/containers/contexts/abTest.context';
import { TOP, MenuDispatchContext } from 'app/containers/contexts/menu.context';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { LocaleContext } from 'app/containers/LocaleProvider';

import {
    DEFAULT_CATEGORIES,
} from 'app/const/categories';
import { isSubscription, SUBSCRIPTION_TYPES } from 'app/const/subscription';
import { DESKTOP_ANIMATION_PARAMS, MOBILE_ANIMATION_PARAMS } from 'app/const/menuAnimation';
import LINKS from 'app/const/links';
import { PAYMENT_PARAM_RECURRENT } from 'app/const/billing-payment-methods';

import { isDesktop } from 'app/utils/resolution';
import { Mobile, Desktop } from 'app/components/Responsive';
import { UIButton, UIParagraph } from 'app/components/ui';
import MenuCategoryList from 'app/components/MenuCategoryList';
import OpenBasketButton from 'app/components/OpenBasketButton';
/* BR-1077 */
import { MenuSkeletonPage } from 'app/components/Skeletons/MenuPageSkeletons/menu.skeleton';

import MenuDishesProvider from 'app/containers/MenuDishesProvider';

import Filters from 'app/views/Filters/Filters';
import { AchievementsOpener } from 'app/components/Achievements/AchievementsOpener';
import { AchievementsBannerBig } from 'app/components/Achievements/AchievementsBannerBig';

import BASKET_QUERY from 'app/graphql/network/basket/basketQuery';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import { useSelectedFilters } from 'app/connect/connectToSelectedFilters';
import { getPeriod } from './defaultFilterUtils';
import RedirectToBasketWithGift from './components/RedirectToBasketWithGift';

import './menu.scss';
import { pickCategoryReaction, setDefaultFiltersReaction } from '../../../apollo/reactions/filters';
import { useAddToBasketMutation } from './hooks';
import { useQueryAdapter } from './hooks/useQueryAdapter';


const { TRIAL_FIRST_ORDER_SET } = LINKS;

const dialogTexts = {
    ru: {
        strongText: 'Нужно освободить корзину,',
        regularText: 'чтобы добавить туда блюдо',
        confirmText: 'Хорошо',
        rejectText: 'Нет, не надо',
    },
    en: {
        strongText: 'Empty your basket',
        regularText: 'to add this dish',
        confirmText: 'OK',
        rejectText: 'No, go back',
    },
};


class Menu extends React.Component {
    static getDerivedStateFromProps(props) {
        const {
            selectedFilters: {
                selectedPeriod,
                selectedCategoryId,
            },
        } = props;

        if (selectedPeriod && selectedCategoryId) {
            return { isFiltersSelected: true };
        }

        return null;
    }

    state = {
        filters: 'close', // open, close
        isFiltersSelected: false,
    };

    isDefaultFiltersSaved = false;

    componentDidMount() {
        /*
            Таймаут добавлен, чтобы при переходе из ЛК в мобильных устройствах
            плавнее работала анимация закрытия меню
        */
        try {
            setTimeout(() => this.setDefaultValues(), 300);
        } catch (e) {
            Raven.captureException(e);
        }
    }

    componentDidUpdate() {
        try {
            this.setDefaultValues();
        } catch (e) {
            Raven.captureException(e);
        }
    }

    async setDefaultValues() {
        const {
            menuDatesContext: { state: menuDates },
            filterQuery: { menuFilter },
            selectedFilters: {
                isUserChangesTags,
            },
            urlQuery,
        } = this.props;

        if (!menuFilter || this.isDefaultFiltersSaved) return;
        if (!menuDates || isEmpty(menuDates.rawDates)) return;

        const period = getPeriod({
            periodFromUrl: urlQuery.period,
            allPeriods: menuFilter.periods,
            location: window.location,
            menuDates,
        });
        const periodStart = period.start;

        const { categories: [category] } = period;

        const categoryId = category.id;
        const defaultCategories = [...DEFAULT_CATEGORIES];

        let defaultTags = [];
        if (!isUserChangesTags && urlQuery.tags) {
            defaultTags = period.tags
                .filter((tag) => urlQuery.tags.includes(tag.id))
                .map((tag) => tag.id);
        }

        setDefaultFiltersReaction({
            variables: {
                periodStart,
                categoryId,
                categories: defaultCategories,
                tags: defaultTags,
            },
        });

        this.isDefaultFiltersSaved = true;
    }

    handleSelectCategory = async (categoryId) => {
        analyticService.push({ eventName: 'Categories_In_Viewport', categoryId });
        pickCategoryReaction({ categoryId });
    };


    handleOpenFilters = () => this.setState({ filters: 'open' });

    handleCloseFilters = () => {
        this.setState({ filters: 'close' });
    };

    handleToggleFilters = () => {
        // UI
        const { filters } = this.state;

        if (filters === 'open') {
            this.handleCloseFilters();
        } else {
            this.handleOpenFilters();
            analyticService.push({
                eventName: 'Change_Filter_Item',
                itemKey: 'open',
            });
        }
    };

    handleClickAddDishToBasket = (dishData) => {
        const { handleAddToBasketMutation } = this.props;
        handleAddToBasketMutation(dishData);
    };

    createClearBasketWithDialogHandler = (specificAddToBasketFn) => async () => {
        const {
            basketQuery,
            dispatchOverlayContext: { pushDialogOverlay, closeLastOverlays },
            promocode: { promocode: promocodeFromCache },
            updateBilling,
            locale: { locale },
            handleCleanUrlParams,
        } = this.props;

        // определяем, установлен ли триальный промокод в кэш
        const isTrialPromocode = promocodeFromCache === TRIAL_FIRST_ORDER_SET.promoMenu;

        // функция подтверждения дилога
        const handleConfirmDialog = async () => {
            const { setIsBasketProcessing } = this.props;
            setIsBasketProcessing(true);

            closeLastOverlays();

            handleCleanUrlParams();

            /*
                очищается корзина и изменяются параметры оплаты
                В basketQuery.refetch не нужен clear, так как триальные блюда будут сами удалены из корзины
                из-за того, что в URL-е нет параметра extra=5x2 (он удаляется при закрытии корзины)
            */
            // await basketQuery.refetch({ clear: true });
            await basketQuery.refetch();
            promocodeVar('');

            await updateBilling({ variables: { promocode: '', payment_method: 'recurrent' } });
            subscriptionTypeVar(SUBSCRIPTION_TYPES.subscription);
            // специфичная для компонента блюда функция добавления в корзину (например, с анимацией)
            await specificAddToBasketFn();

            setIsBasketProcessing(false);
        };

        const dialogData = {
            ...dialogTexts[locale],
            onConfirm: handleConfirmDialog,
            notifyOnly: false,
            oneRowButtons: true,
        };

        /**
        * если не установлен триальный промокод и в корзине не находится триальный набор,
        * то просто доабвляем блюдо
        */
        if (!isTrialPromocode && basketQuery.cart.typeOfSet !== '5x2') {
            // специфичная для компонента блюда функция добавления в корзину (например, с анимацией)
            specificAddToBasketFn();
            return;
        }

        // иначе отображаем диалог очистки корзины и изменяем параметры оплаты
        pushDialogOverlay('clear_basket_dialog_menu', dialogData);
    };

    handleResetFilters = () => {
        const {
            menuDispatchContext,
        } = this.props;

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'clear',
        });

        resetSelectedFiltersReaction();

        const scrollingTimeout = 1000;

        setTimeout(() => {
            menuDispatchContext({
                type: 'scrollTo',
                payload: { target: TOP },
            });

            pickCategoryReaction({ categoryId: '3.1' });
        }, scrollingTimeout);
    };

    renderResetFiltersButton() {
        const { isDishesUpdating } = this.props;

        const buttonText = 'Остальные блюда';

        const commonProps = {
            onClick: this.handleResetFilters,
            'aria-label': 'очистить фильтры',
        };

        const resetFiltersButtonContainerClasses = classNames({
            'menu__reset-filters-button-container': true,
            'menu-updating': isDishesUpdating,
        });

        const resetFiltersButtonDescriptionWrapClasses = classNames({
            'menu__reset-filters-button-description-wrapper': true,
        });

        return (
            <div styleName={resetFiltersButtonContainerClasses}>
                <Mobile>
                    <div styleName={resetFiltersButtonDescriptionWrapClasses}>
                        <UIParagraph level="5.1" styleName="menu__reset-filters-button-description">
                            Пока это все блюда
                            <br />
                            по&nbsp;выбранным фильтрам.
                            <br />
                            Но у нас есть ещё десятки классных блюд!
                        </UIParagraph>
                    </div>
                    <UIButton
                        colorType="secondary"
                        {...commonProps}
                    >
                        {buttonText}
                    </UIButton>
                </Mobile>

                <Desktop>
                    <button
                        type="button"
                        styleName="menu__reset-filters-button"
                        {...commonProps}
                    >
                        {buttonText}
                    </button>
                </Desktop>
            </div>
        );
    }

    renderDishes({ isFiltersOpen, menuDishesProps }) {
        const {
            abTestDataContext,
            overlayIsOpen,
            showMenu,
            selectedFilters,
            filterQuery,
            basketQuery,
            userQuery,
            subscriptionTypeQuery: { subscriptionType },
            openDishDetails,
            openBasket,
            dishDetailsPathname,
            onMainDishesRendered,

            isMobileMenuDishesVisible,
            showFilterChangedAnimation,
            setDishesUpdatingState,
            isDishesUpdating,

            promocode: { promocode },
            onSetPeriod,
            setTemporaryBasketPeriod,
        } = this.props;

        const { isBasketProcessing } = this.props;

        const isDesktopResolution = isDesktop();

        if ((!isDesktopResolution && (!showMenu))) return null;

        const isSubscriptionActivated = isSubscription(subscriptionType);

        const menuCategoryListProps = {
            addToBasketAnimationTarget: isDesktopResolution ? DESKTOP_ANIMATION_PARAMS : MOBILE_ANIMATION_PARAMS,
            isFiltersOpen,
            selectedFilters,
            filterQuery,
            basketQuery,
            userQuery,
            isSubscriptionActivated,
            isMobileMenuDishesVisible,
            showFilterChangedAnimation,
            onClickAddToBasket: this.handleClickAddDishToBasket,
            createClearBasketWithDialogHandler: this.createClearBasketWithDialogHandler,
            openDishDetails,
            openBasket,
            onCategoryEntered: this.handleSelectCategory,
            dishDetailsPathname,
            onMainDishesRendered,
            setDishesUpdatingState,
            isDishesUpdating,
            overlayIsOpen,
            promocode,
            onSetPeriod,
            setTemporaryBasketPeriod,
        };

        /**
         * @TODO Отрефакторить логику выбора цена. Попробовать вынести в один слой.
         * Тaк как во многих компонентах дублируется логика. Например в Basket.js 595 строка.
        */
        const price = basketQuery.cart ? basketQuery.cart.totals.total_discount_price : null;
        const originalPrice = basketQuery.cart
            ? (basketQuery.cart.totals.total_price + basketQuery.cart.totals.delivery_price)
            : null;

        const { hasDataForRenderDishes } = menuDishesProps;

        const basketPreviewTestValue = abTestDataContext?.basket_preview_in_menu === 'with_preview';
        const canRenderOpenBasketButton = basketPreviewTestValue ? price > 0 : true;

        if (!hasDataForRenderDishes) return null;

        return (
            <div styleName="menu__dishes-container">
                <MenuCategoryList
                    {...menuDishesProps}
                    {...menuCategoryListProps}
                />

                {!selectedFilters.isSelectedDefaultFilters && this.renderResetFiltersButton()}

                <Mobile>
                    {canRenderOpenBasketButton && (
                        <OpenBasketButton
                            price={price}
                            originalPrice={originalPrice}
                            onClick={openBasket}
                            loading={basketQuery.loading || isBasketProcessing}
                            promocodeConditions={basketQuery.cart.discount_conditions}
                        />
                    )}
                    {/* UNTIL NY 2026 */}
                    {/* <AchievementsOpener>
                        {({ togglePopup }) => (
                            <AchievementsBannerBig
                                onClick={togglePopup}
                            />
                        )}
                    </AchievementsOpener> */}
                </Mobile>
            </div>
        );
    }

    render() {
        const { filters, isFiltersSelected } = this.state;
        const {
            basketQuery,
            filterQuery: { menuFilter },
            selectedFilters: { selectedPeriod },
            openBasket,
            overlayIsOpen,
            hiddenElements: {
                comment: isCommentHiddenByUrl,
                payment_button_recurrent: isRecurrentHiddenByUrl,
            },
            onClickSubscriptionInfoOpen,
            setDishesUpdatingState,
        } = this.props;

        const isFiltersOpen = filters === 'open';

        const period = menuFilter && menuFilter.periods.find((p) => p.start === selectedPeriod);
        const hideSubscription = period && period.hiddenPaymentMethods && period.hiddenPaymentMethods.includes(PAYMENT_PARAM_RECURRENT);
        const isNeededRenderSubscription = !(isRecurrentHiddenByUrl || hideSubscription);

        const menuClasses = classNames({
            /* стремный хак, чтобы в корзине не было лишнего скролла */
            'is-hidden': overlayIsOpen,
            menu: true,
        });


        if (!isFiltersSelected) {
            return (
                <MenuSkeletonPage />
            );
        }

        return (
            <MenuDishesProvider
                menuFilter={menuFilter}
                basketQuery={basketQuery}
                setDishesUpdatingState={setDishesUpdatingState}
                fetchPolicy="network-only"
                render={(menuDishesProps) => (
                    <>
                        {!menuDishesProps.hasDataForRenderDishes && (
                            <MenuSkeletonPage />
                        )}
                        <div styleName={menuClasses}>
                            <Desktop>
                                {menuDishesProps.hasDataForRenderDishes && (
                                    <>
                                        {isFiltersOpen && (
                                            <button
                                                type="button"
                                                aria-label="Закрыть фильтры"
                                                styleName="menu__close-filters"
                                                onClick={this.handleCloseFilters}
                                            />
                                        )}
                                        <Filters
                                            {...menuDishesProps}
                                            isCommentEditEnabled={!isCommentHiddenByUrl}
                                            // isNeededRenderSubscription={!isRecurrentHiddenByUrl}
                                            isNeededRenderSubscription={isNeededRenderSubscription}
                                            overlayIsOpen={overlayIsOpen}
                                            isFiltersOpen={isFiltersOpen}
                                            onToggle={this.handleToggleFilters}
                                            onClose={this.handleCloseFilters}
                                            onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
                                        />
                                    </>
                                )}
                                <RedirectToBasketWithGift
                                    basketQuery={basketQuery}
                                    openBasket={openBasket}
                                />
                            </Desktop>
                            {this.renderDishes({ isFiltersOpen, menuDishesProps })}
                        </div>
                    </>
                )}
            />
        );
    }
}

Menu.propTypes = {
    abTestDataContext: PropTypes.shape({
        basket_preview_in_menu: PropTypes.string,
    }).isRequired,
    locale: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,

    hiddenElements: PropTypes.shape({
        payment_button_recurrent: PropTypes.bool,
        comment: PropTypes.bool,
    }).isRequired,

    overlayIsOpen: PropTypes.bool.isRequired,
    showFilterChangedAnimation: PropTypes.bool.isRequired,

    updateBilling: PropTypes.func.isRequired,

    showMenu: PropTypes.bool.isRequired,
    isMobileMenuDishesVisible: PropTypes.bool.isRequired,

    filterQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }).isRequired,

    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
        selectedCategoryId: PropTypes.string,
        selectedTags: PropTypes.arrayOf(
            PropTypes.string,
        ),
        isSelectedDefaultFilters: PropTypes.bool.isRequired,
        isUserChangesTags: PropTypes.bool.isRequired,
    }).isRequired,

    userQuery: PropTypes.shape({
        user: PropTypes.shape({}),
    }).isRequired,
    basketQuery: PropTypes.shape({
        cart: PropTypes.shape({
            sections: PropTypes.arrayOf(PropTypes.shape({})),
            totals: PropTypes.shape({
                total_discount_price: PropTypes.number,
            }),
        }),
        loading: PropTypes.bool,
        refetch: PropTypes.func,
    }),

    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string.isRequired,
    }).isRequired,

    urlQuery: PropTypes.shape({
        period: PropTypes.string,
        category: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
        categories: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,

    openDishDetails: PropTypes.func.isRequired,
    openBasket: PropTypes.func.isRequired,

    // Actions
    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,
    onSetPeriod: PropTypes.func.isRequired,
    setTemporaryBasketPeriod: PropTypes.func.isRequired,

    menuDispatchContext: PropTypes.func.isRequired,
    dishDetailsPathname: PropTypes.string.isRequired,
    onMainDishesRendered: PropTypes.func.isRequired,
    setDishesUpdatingState: PropTypes.func.isRequired,
    isDishesUpdating: PropTypes.bool.isRequired,

    dispatchOverlayContext: PropTypes.shape({
        pushDialogOverlay: PropTypes.func.isRequired,
        closeLastOverlays: PropTypes.func.isRequired,
    }).isRequired,
    promocode: PropTypes.shape({
        promocode: PropTypes.string,
    }).isRequired,

    handleCleanUrlParams: PropTypes.func.isRequired,
};


const MenuDataAccessContainer = (props) => {
    const [isBasketProcessing, setIsBasketProcessing] = useState(false);

    const history = useHistory();
    const location = useLocation();
    /* CONTEXTS */
    const localeContext = useContext(LocaleContext);
    const menuDatesContext = useContext(menuDatesState);
    const menuDispatchContext = useContext(MenuDispatchContext);
    const dispatchOverlayContext = useContext(OverlayContextDispatch);
    const overlayDataContext = useContext(OverlayContextData);
    const abTestData = useContext(abTestDataContext);
    const { filterQuery } = useContext(FiltersContext);
    const selectedFilters = useSelectedFilters();

    // TODO: тут ставиться дефолтное значение параметров в URL VI
    const queryProps = useQueryAdapter({ selectedFilters, history });
    const { handleAddToBasketMutation, basketLoading } = useAddToBasketMutation();

    // eslint-disable-next-line camelcase
    const { customization_in_basket, fetchABTestValue } = useContext(abTestDataContext);

    const { data: basketData, loading, refetch } = useQuery(BASKET_QUERY, {
        variables: {
            period: selectedPeriodVar(),
        },
    });

    useEffect(
        () => {
            // eslint-disable-next-line camelcase
            const isUnfetched = customization_in_basket === 'unfetched';

            if (isUnfetched && !isDesktop()) {
                fetchABTestValue('customization_in_basket');
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );


    useEffect(
        () => {
            analyticService.push({
                eventName: 'Pageview',
                pagePath: 'main',
                pageUrl: window.location.href,
            });
        },
        [],
    );

    useEffect(() => {
        setIsBasketProcessing(basketLoading);
    }, [basketLoading]);

    if (loading) {
        return (
            <MenuSkeletonPage />
        );
    }

    return (
        <Menu
            {...props}
            {...queryProps}
            location={location}
            filterQuery={filterQuery}
            locale={localeContext}
            menuDatesContext={menuDatesContext}
            menuDispatchContext={menuDispatchContext}
            dispatchOverlayContext={dispatchOverlayContext}
            overlayDataContext={overlayDataContext}
            abTestDataContext={abTestData}
            handleAddToBasketMutation={handleAddToBasketMutation}
            isBasketProcessing={isBasketProcessing}
            setIsBasketProcessing={setIsBasketProcessing}
            basketQuery={{
                ...basketData,
                loading,
                refetch,
                fromDAC: true,
            }}
        />
    );
};

export default connect(MenuDataAccessContainer);

